import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_f3uejz4_gisdEmqr8oiqo40ZtL_ImngJsD7HSlp7_ps from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import plugin_h_pA3yusmDTJ8f7Mvx2nEWTVV4itdwLzF7i0rRgd_FI from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import session_client_b5UNkRiOS3MV2QfaMwoZvpWEWDmuuq5PgrWgkUjIN3c from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import a11y_client_vx4rlqTIIUr6seXLJh30lWBixwRG__h49jK9LQBKQA8 from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/a11y.client.ts";
import core_fetch__B9v_3PllOJU0QkDTIa_wNf_DEUuOdmvWVmalJL9H7o from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/core-fetch.ts";
import datadog_client_HImNUkxDT_OdAiYi7ZXU3Kn5ZFiVU9T7Iy0kZXPHX2g from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/datadog.client.ts";
import error_handler_b94uaMSpE9w_QzuBnuJFETjvfxz_GY1_KR4D1ySwna0 from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/error-handler.ts";
import ks_toast_client_3MqTH7lvM6EMGXk89fzzC5FQ3agdLCfg3fGA5S_N14c from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/ks-toast.client.ts";
import vue_query_mAdjlicKbpO1UnAZ7vXXXzGIhtuQpOlfwkLVjVkGKxE from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/plugins/vue-query.ts";
import _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk from "/builds/aschehoug/juridika/juridika-frontend-nuxt/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_f3uejz4_gisdEmqr8oiqo40ZtL_ImngJsD7HSlp7_ps,
  plugin_h_pA3yusmDTJ8f7Mvx2nEWTVV4itdwLzF7i0rRgd_FI,
  session_client_b5UNkRiOS3MV2QfaMwoZvpWEWDmuuq5PgrWgkUjIN3c,
  siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w,
  inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o,
  titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  a11y_client_vx4rlqTIIUr6seXLJh30lWBixwRG__h49jK9LQBKQA8,
  core_fetch__B9v_3PllOJU0QkDTIa_wNf_DEUuOdmvWVmalJL9H7o,
  datadog_client_HImNUkxDT_OdAiYi7ZXU3Kn5ZFiVU9T7Iy0kZXPHX2g,
  error_handler_b94uaMSpE9w_QzuBnuJFETjvfxz_GY1_KR4D1ySwna0,
  ks_toast_client_3MqTH7lvM6EMGXk89fzzC5FQ3agdLCfg3fGA5S_N14c,
  vue_query_mAdjlicKbpO1UnAZ7vXXXzGIhtuQpOlfwkLVjVkGKxE,
  _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM,
  defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk
]