
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexZOnM5kdJfyJ3PbXmaA7eKn5BnWJfn_PAc_45KZGi8rlk8Meta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/index.vue?macro=true";
import { default as logg_45innomPC2kRnBfgVNkk16hUl3l1Ms5zgSXdneAYVS_ICZpgMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/logg-inn.vue?macro=true";
import { default as brukxAnKlc3w6DeVsXUA9cN3iaDMLIja5LGBv0U6Dl2S79EMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/bruk.vue?macro=true";
import { default as indexIkRid5HTle_452NN64_45yCNvBwMjTGuarRtuy76bOyC8SEMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/index.vue?macro=true";
import { default as fakturaDF2sAW08_l5IuT2eI3FnJm9iBVPsMHERLlT0KZhd7SkMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/faktura.vue?macro=true";
import { default as abonnementk_45ensWyARNuZc3YwX4cBdfINgselTtehi6dzqKU4XlkMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/abonnement.vue?macro=true";
import { default as referansekoderAYSbfAQNee93lsuSE_GUBT_yX6jpeWbCuMhVM40DpoMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/referansekode.vue?macro=true";
import { default as administratorerVCeoHH0ot1EM5SYzyL3ERKTKYY0m33ZZpHP_phjcp_45sMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/administratorer.vue?macro=true";
import { default as din_45kontoSxxjORLx55AQahw8hU9FVi2HOBVVz_C_LxHRqSwcSMkMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto.vue?macro=true";
import { default as personvernfaLDYmIqX8Ic1rd_45dirHkIbwRB3Y_z5YbHRpMbvLdXcMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/personvern.vue?macro=true";
import { default as brukeravtaleAR4wzPHbkrprKZAXO4z_Y5SvrzZOk6XN2z_45mxp4pLFQMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/brukeravtale.vue?macro=true";
import { default as indexLu4hg7ktqgOJlqQNA_45tkiTxYxAFEf8XMZB26DKSytWUMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/index.vue?macro=true";
import { default as _91slug_93DM8FPb0fg5jpCR_VOBmXf7VJBaj4OP7uVemTcUg8eFcMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/[slug].vue?macro=true";
import { default as indexKkN0Fm3vrKk2gpsmciwLWhbsmpeIr5lhnCfsP3NGjOwMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/faglitteratur/index.vue?macro=true";
import { default as _91_46_46_46id_93rzEc83NIjqQbqW4fcoBkie_7eGRJcJJlbM6Ud8mKriEMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/faglitteratur/[...id].vue?macro=true";
import { default as indexEBohyNw7tgjFNcXPRTDo1Gv_45MljT_6aj2z5v_amG0KQMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/index.vue?macro=true";
import { default as kvitteringcgL5_j1HEV2yLhilxwRLyPaqdkEEz57v0rI_P_HTfX0Meta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/kvittering.vue?macro=true";
import { default as oppsummeringBEcxMWBt3SaY66mV_45_45U93XjDv_45ONMFD1n4U9FGBNo_454Meta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/oppsummering.vue?macro=true";
import { default as handlekurvt6rNcs6HHjK8G3BFfBn_45BdCtsFYfPjBnD04XxPu28t8Meta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv.vue?macro=true";
import { default as bruk_45av_45informasjonskapslerE3GaMpaZtW_krOMkUVrewwIUfCfbEeyyzjte9pXl3iEMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/bruk-av-informasjonskapsler.vue?macro=true";
import { default as index7MgFJB3Ls9bTLeT5yV3aw1M0D5fnC0vGHcVLZc2ysHEMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/index.vue?macro=true";
import { default as indexhDS_E1g4qTqukOSqy49zaCGhlELrnnI1c6OTZfLrh0cMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/index.vue?macro=true";
import { default as _91slug_93pOF5cSlvSZFszS5Vq9WBrPOB_45BQp2uOIiD_zACpY4IgMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/[slug].vue?macro=true";
import { default as _91_46_46_46lovId_935nOywK_7RZEtxHPva9iusaYgNrTP5Qhd_1HWlt1D85MMeta } from "/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/[...lovId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexZOnM5kdJfyJ3PbXmaA7eKn5BnWJfn_PAc_45KZGi8rlk8Meta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/index.vue")
  },
  {
    name: "logg-inn",
    path: "/logg-inn",
    meta: logg_45innomPC2kRnBfgVNkk16hUl3l1Ms5zgSXdneAYVS_ICZpgMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/logg-inn.vue")
  },
  {
    name: "din-konto-layout",
    path: "/din-konto",
    meta: din_45kontoSxxjORLx55AQahw8hU9FVi2HOBVVz_C_LxHRqSwcSMkMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto.vue"),
    children: [
  {
    name: "din-konto-bruk",
    path: "bruk",
    meta: brukxAnKlc3w6DeVsXUA9cN3iaDMLIja5LGBv0U6Dl2S79EMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/bruk.vue")
  },
  {
    name: "din-konto",
    path: "",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/index.vue")
  },
  {
    name: "din-konto-faktura",
    path: "faktura",
    meta: fakturaDF2sAW08_l5IuT2eI3FnJm9iBVPsMHERLlT0KZhd7SkMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/faktura.vue")
  },
  {
    name: "din-konto-abonnement",
    path: "abonnement",
    meta: abonnementk_45ensWyARNuZc3YwX4cBdfINgselTtehi6dzqKU4XlkMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/abonnement.vue")
  },
  {
    name: "din-konto-referansekode",
    path: "referansekode",
    meta: referansekoderAYSbfAQNee93lsuSE_GUBT_yX6jpeWbCuMhVM40DpoMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/referansekode.vue")
  },
  {
    name: "din-konto-administratorer",
    path: "administratorer",
    meta: administratorerVCeoHH0ot1EM5SYzyL3ERKTKYY0m33ZZpHP_phjcp_45sMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/din-konto/administratorer.vue")
  }
]
  },
  {
    name: "personvern",
    path: "/personvern",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/personvern.vue")
  },
  {
    name: "brukeravtale",
    path: "/brukeravtale",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/brukeravtale.vue")
  },
  {
    name: "forfattere",
    path: "/forfattere",
    meta: indexLu4hg7ktqgOJlqQNA_45tkiTxYxAFEf8XMZB26DKSytWUMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/index.vue")
  },
  {
    name: "forfattere-slug",
    path: "/forfattere/:slug()",
    meta: _91slug_93DM8FPb0fg5jpCR_VOBmXf7VJBaj4OP7uVemTcUg8eFcMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/forfattere/[slug].vue")
  },
  {
    name: "faglitteratur",
    path: "/faglitteratur",
    meta: indexKkN0Fm3vrKk2gpsmciwLWhbsmpeIr5lhnCfsP3NGjOwMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/faglitteratur/index.vue")
  },
  {
    name: "faglitteratur-id",
    path: "/faglitteratur/:id(.*)*",
    meta: _91_46_46_46id_93rzEc83NIjqQbqW4fcoBkie_7eGRJcJJlbM6Ud8mKriEMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/faglitteratur/[...id].vue")
  },
  {
    name: "handlekurv-layout",
    path: "/handlekurv",
    meta: handlekurvt6rNcs6HHjK8G3BFfBn_45BdCtsFYfPjBnD04XxPu28t8Meta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv.vue"),
    children: [
  {
    name: "handlekurv",
    path: "",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/index.vue")
  },
  {
    name: "handlekurv-kvittering",
    path: "kvittering",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/kvittering.vue")
  },
  {
    name: "handlekurv-oppsummering",
    path: "oppsummering",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/handlekurv/oppsummering.vue")
  }
]
  },
  {
    name: "bruk-av-informasjonskapsler",
    path: "/bruk-av-informasjonskapsler",
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/bruk-av-informasjonskapsler.vue")
  },
  {
    name: "lover-og-lovkommentarer",
    path: "/lover-og-lovkommentarer",
    meta: index7MgFJB3Ls9bTLeT5yV3aw1M0D5fnC0vGHcVLZc2ysHEMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/index.vue")
  },
  {
    name: "produktkatalog",
    path: "/produktkatalog",
    meta: indexhDS_E1g4qTqukOSqy49zaCGhlELrnnI1c6OTZfLrh0cMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/index.vue")
  },
  {
    name: "produktkatalog-slug",
    path: "/produktkatalog/:slug()",
    meta: _91slug_93pOF5cSlvSZFszS5Vq9WBrPOB_45BQp2uOIiD_zACpY4IgMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/(nettbutikk)/produktkatalog/[slug].vue")
  },
  {
    name: _91_46_46_46lovId_935nOywK_7RZEtxHPva9iusaYgNrTP5Qhd_1HWlt1D85MMeta?.name ?? "lover-og-lovkommentarer-lovId",
    path: "/lover-og-lovkommentarer/:lovId(.*)*",
    meta: _91_46_46_46lovId_935nOywK_7RZEtxHPva9iusaYgNrTP5Qhd_1HWlt1D85MMeta || {},
    component: () => import("/builds/aschehoug/juridika/juridika-frontend-nuxt/app/pages/lover-og-lovkommentarer/[...lovId].vue")
  }
]